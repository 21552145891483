import { handleChangeUserInfo, handleLangChange } from "@/libs/event-bus";
export const shared = {
    data() {
        return {};
    },
    computed: {
        app() {
            return {
                domainHost: this.$store.getters["app/domainHost"] + "/",
                language: this.$store.getters["app/lang"],
                dashLanguage: this.$store.getters["app/dashLang"],
                ScreenSize: this.$store.getters["app/currentBreakPoint"]
            };
        },
        userData() {
            return JSON.parse(localStorage.getItem("userData"));
        }
    },

    created() {},
    methods: {
        handleChangeUserInfo,
        handleLangChange
    }
};
