<template>
  <div>
    <b-row class="mb-2 mt-2">
      <b-col cols="4">
        <h5>فلترة حسب :</h5>
      </b-col>
      <b-col cols="4">
        <div>
          <ek-input-select
            label="اسم المرسل"
            name="اسم المرسل"
            placeholder="اختر اسم المرسل"
            v-model="filter.sender"
            :options="[...activitiesList, { id: userData.id, name: 'إدارة التطبيق' }]"
            clearable
          >
          </ek-input-select>
        </div>
      </b-col>
      <b-col cols="4">
        <div>
          <span> تاريخ الإشعار :</span>
          <ek-date-picker
            name="تاريخ الاشعار"
            placeholder="حدد تاريخ الإشعار"
            range
            v-model="filter.dateCreated"
          ></ek-date-picker>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        v-for="(notifications, i) in filterNotification.filter(filterNotifications)"
        :key="notifications.id"
      >
        <b-card>
          <div style="display: flex; justify-content: space-between">
            <p style="color: gray">المرسل:{{ notifications.sendBy }}</p>
            <p style="color: gray" :id="`receivers-${i}`">
              المرسل إليه:{{ getNameOfType(notifications.sendTo) }}
            </p>
            <b-tooltip :target="`receivers-${i}`" triggers="hover">
              <span
                v-for="(name, index) in notifications.receivers"
                :key="index"
                class="d-block"
              >
                {{ name }}
              </span>
            </b-tooltip>
            <p>
              {{ formatNotificationDate(notifications.responseDate) }}
              <b-button
                variant="transparnt"
                class="p-0"
                @click="deleteNotification(notifications.id)"
              >
                <unicon fill="gray" height="20px" name="trash-alt"></unicon>
              </b-button>
            </p>
          </div>
          <hr class="mt-0" />
          <div>
            <h4>{{ notifications.title }}</h4>
            <h5 style="color: gray">{{ notifications.body }}</h5>
            <b-img
                v-if="notifications.imageUrl "
              :src="$store.getters['app/domainHost'] + '/' + notifications.imageUrl"
              class="notification-card-img mt-1 obj-cover"
            ></b-img>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center">
        <ek-pagination
          :items="notificationsList"
          v-model="filterNotification"
          :pageLength="5"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";
import { shared } from "@global/shared";

export default {
  mixins: [dateManipulatoin, shared],
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
      accountTypes: ({ accounts }) => accounts.accountTypes,
      activitiesList: ({ activities }) => activities.activities,
    }),
    ...mapGetters(["notificationsList"]),
  },
  data() {
    return {
      filterNotification: [],
      filter: {
        sender: "",
        dateCreated: "",
      },
    };
  },
  methods: {
    ...mapActions(["getNotificationsList", "deleteNotification"]),
    formatNotificationDate(responseDate) {
      let dete = `  ${new Date(responseDate).toLocaleDateString()} `;
      dete += `  (${new Date(responseDate).getHours()}:${new Date(
        responseDate
      ).getMinutes()}) `;
      dete += new Date(responseDate).getHours() >= 12 ? "pm" : "am";
      return dete;
    },
    filterNotifications(el) {
      console.log("sender", this.filter.sender);

      console.log("el", el);

      let [
        notificationStartDate = null,
        notificationEndDate = null,
      ] = this.filter.dateCreated?.replace("to", "").replace(" ", "").split(" ");

      return (
        (el.senderId == this.filter.sender || !this.filter.sender) &&
        ((this.setDateTime(new Date(el.responseDate)) >=
          this.setDateTime(new Date(notificationStartDate)) &&
          this.setDateTime(new Date(el.responseDate)) <=
            this.setDateTime(new Date(notificationEndDate))) ||
          this.filter.dateCreated.length == 0)
      );
    },
    getNameOfType(id) {
      let type = { ...this.accountTypes.find((el) => el.id == id) };
      return type.name || "غير محدد";
    },
  },
  created() {
    this.getNotificationsList();
  },
};
</script>
<style scoped>
.notification-card-img{
    width: 100%;
    border-radius: 6px;
    height: 300px;
}
</style>